const serverUrl = (process.env.REACT_APP_API_URL) ? process.env.REACT_APP_API_URL : 'http://127.0.0.1:5000';

const getSourceInfo = async function () {
    try {
        console.log('Deployment URL:', serverUrl);
        const sourceResponse = await fetch(`${serverUrl}/get-source`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
        });

        if (!sourceResponse.ok) {
            console.log('No source information available or error occurred.');
            return [];
        }

        const sourceData = await sourceResponse.json();
        return sourceData;
    } catch (error) {
        console.error('There was a problem with the fetch operation:', error);
        return [];
    }
}

export const handleFileChange = async (event, setUploadLabel, setUploadLoading, formRef, fileInputRef) => {
    event.preventDefault();
    const formData = new FormData(formRef.current);
    const files = fileInputRef.current.files;
    setUploadLoading(true);
    for (let i = 0; i < files.length; i++) {
        formData.append("files[]", files[i]);
    }

    try {
        console.log('Deployment URL:', serverUrl);
        const response = await fetch(`${serverUrl}/upload`, {
            method: 'POST',
            body: formData,
            headers: {
                'Accept': 'application/json',
            }
        });

        if (response.ok) {
            setUploadLabel("Upload More");
            setUploadLoading(false);
        } else {
            throw new Error('Network response was not ok.');
        }
    } catch (error) {
        alert("File upload failed: " + error);
    }
};


export const handleSendMessage = async (event, setInputText, inputText, messages, setMessages, setMsgLoading, model, language) => {
    if (event != null) {
        event.preventDefault();
    }
    const api_name = (event == null) ? 'handle-query-no-rag' : 'handle-query';

    if (!inputText.trim()) return; // Prevent sending empty messages

    // Get a snapshot of the current messages + the user's message
    // to send to the server to get an answer
    const userMessage = { text: inputText, isBot: false };
    const body = {
        chatHistory: [...messages, userMessage],
        question: inputText,
        model: model,
        language: language
    }

    // Add a new empty bot message to the UI
    const botMessage = { text: '', isBot: true };
    setMessages([...messages, userMessage, botMessage]);
    setInputText('');
    setMsgLoading(true);

    // Send the user's message to the server and wait for a response.
    // This response will be streamed to this component.
    console.log('Deployment URL:', serverUrl);
    const response = await fetch(`${serverUrl}/${api_name}`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(body),
    });


    if (!response.body) return;

    // Set up the infrastructure to stream the response data
    let decoder = new TextDecoderStream();
    const reader = response.body.pipeThrough(decoder).getReader()
    let accumulatedAnswer = ""
    const sourceData = await getSourceInfo();
    while (true) {
        var { value, done } = await reader.read();
        setMsgLoading(false);
        if (done) break;
        accumulatedAnswer += value;
        setMessages(currentHistory => {
            const updatedHistory = [...currentHistory]
            const lastChatIndex = updatedHistory.length - 1
            updatedHistory[lastChatIndex] = {
                ...updatedHistory[lastChatIndex],
                text: accumulatedAnswer,
                sourceData: sourceData
            }
            return updatedHistory
        })
    }
};